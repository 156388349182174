<template>
  <router-view />
</template>
<script>

import '@/filters'; //TODO: WE DONT NEED THIS RIGHT?

export default {
  name: 'Main', //TODO: RENAME
  created() {
    // if affkey exists, add to store
    if (this.$route.query.affkey || (this.$cookies && this.$cookies.get('affiliate'))) {
      let affkey = '';
      this.$route.query.affkey ? (affkey = this.$route.query.affkey) : (affkey = this.$cookies.get('affiliate'));
      this.$store.commit('params/setAffkey', affkey);
    }
    // if gclid exists, add to store
    if (this.$route.query.gclid || (this.$cookies && this.$cookies.get('gclid'))) {
      let gclid = '';
      this.$route.query.gclid ? (gclid = this.$route.query.gclid) : (gclid = this.$cookies.get('gclid'));
      this.$store.commit('params/setGclid', gclid);
    }
    // if return url exists, add to store
    if (this.$route.query.return_url) {
      let retURL = this.$route.query.return_url;
      this.$store.commit('params/setReturnURL', retURL);
    }
    // if campaign id exists, add to store
    if (this.$route.query.campaign_id) {
      let campID = this.$route.query.campaign_id;
      this.$store.commit('params/setCampaignID', campID);
    }
    // if sourceform exists, add to store
    if (this.$route.query.sourceform) {
      this.$store.commit('params/setSourceForm', this.$route.query.sourceform);
    }
  },
};
</script>
